import React from "react"
import Header from "../components/ui/blocks/header"
import {
  createMuiTheme,
  ThemeProvider,
  CssBaseline,
  responsiveFontSizes,
} from "@material-ui/core"
import { ParallaxProvider } from "react-scroll-parallax"
import Fab from "./ui/blocks/fab"
const defaultTheme=createMuiTheme()
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#d69e5a",
    },
    secondary: {
      main: "#fff",
    },
  },
overrides:{
  MuiContainer:{
    maxWidthXl:{
      width:"100%",
      [defaultTheme.breakpoints.up('xl')]:{
        maxWidth:"100vw"
      }
    },
    root:{
      width:"100vw",
      maxWidth:"100vw"
    }
  }
},
  typography: {
    fontFamily: "Montserrat",
    h4:{
      padding:"16px 0",

    },
    h1:{
      padding:"16px 0",

    },
    h2:{
      padding:"16px 0"
    },
    body1:{
      fontWeight:400
    },
    h5:{
      fontWeight:400
    },
    h3:{
      fontWeight:400,
    },
    subtitle2:{
      fontFamily:"Vollkorn",
      fontWeight:"300",
    },
    allVariants:{
      fontWeight:"600"
    }
  },
})

export default function Layout({ children,showNav=true,menuData,disableScroll=false,navCta=null }) {
  return (
    <ThemeProvider theme={responsiveFontSizes(theme,{factor:3})}>
      <CssBaseline />
      <Header items={menuData} showNav={showNav} disableScroll={disableScroll} navCta={navCta}/>
      <ParallaxProvider><div style={{overflow:"hidden"}}>{children}</div></ParallaxProvider>
      <Fab data={menuData} showNav={showNav}/>
    </ThemeProvider>
  )
}
