import React, { useState } from "react"
import { Button, Grid } from "@material-ui/core"
import { TextField } from "formik-material-ui"
import { Field, Form, Formik } from "formik"
import Alert from "./alert"

export default function SanityForm({ fields, template = null, closeIt }) {
  const [alert, setAlert] = useState(false)

  const onSubmit = data => {
    const emaildata = {
      name: "Karaena Vincent",
      email: "info@karaenavincent.com",
      fromemail: "webforms@karaenavincent.co.nz",
      subject: "Website Enquiry",
      authID: "d63a50f1e641bb7fe527eca3a88d5eeb",
      message: {
        textpart: `Hi Karaena, A new enquiry was submitted by ${data.firstName} ${data.lastName}. ${data.firstName} can be contacted on ${data.phone} or ${data.eMail}`,
        htmlpart: `<h3>Hi Karaena</h3>,<br /> A new enquiry was submitted by <strong>${data.firstName} ${data.lastName}</strong>.<br /> ${data.firstName} can be contacted on <strong> ${data.phone} or ${data.eMail} </strong> <br /> Business name: ${data.business} <br /> `,
      },
    }
    setAlert(true)

    if (template) {
      const emailObj = {
        to: data.eMail,
        firstName: data.firstName,
        lastName: data.lastName,
        from: "info@karaenavincent.co.nz",
        templateID: template.mailjetTemplateID,
        authID: "d63a50f1e641bb7fe527eca3a88d5eeb",
      }
      fetch(
        `https://us-central1-all-too-human-6800b.cloudfunctions.net/api?action=sendtemplate&data=${JSON.stringify(
          emailObj
        )}`
      ).then(data => {
        fetch(
          `https://us-central1-all-too-human-6800b.cloudfunctions.net/api?action=sendmail&data=${JSON.stringify(
            emaildata
          )}`
        ).then(data => {
          closeIt(false)
        })
      })
    } else {
      fetch(
        `https://us-central1-all-too-human-6800b.cloudfunctions.net/api?action=sendmail&data=${JSON.stringify(
          emaildata
        )}`
      ).then(data => {
        setAlert(true)
        closeIt(false)
      })
    }
  }
  let initialValues = {}
  fields.forEach(field => {
    initialValues = { ...initialValues, [field.fieldName]: "" }
  })
  return (
    <>
      <Formik onSubmit={onSubmit} initialValues={initialValues}>
        <Form>
          <Grid container justify="center" spacing={1}>
            {fields.map((field, index) => (
              <Grid item xs={12}>
                <Field
                  component={TextField}
                  margin="normal"
                  key={index}
                  placeholder={`Please enter your ${field.label}`}
                  name={field.fieldName}
                  label={field.label}
                  required={field.required}
                  fullWidth
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                type="submit"
                color="primary"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Formik>
      <Alert message="Message Sent." setState={setAlert} open={alert} />
    </>
  )
}
