import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core"
import PhoneIcon from "@material-ui/icons/Phone"
import MessageIcon from "@material-ui/icons/Message"
export default function Fab({ data,showNav }) {
  const [hidden,setHidden]=useState(true)
  const useStyles = makeStyles(theme => ({
    fabContainer: {
      position: "fixed",
      bottom: 0,
      backgroundColor: theme.palette.primary.main,
      display: "flex",
      flexWrap: "nowrap",
      flexDirection: "row",
      justifyContent: "space-evenly",
      alignContent: "center",
      width: "100%",

    },
  }))
  const handlescroll=()=>{
    const offset=window.pageYOffset
    if(offset>40 && hidden){
      setHidden(false)
    }
    else if(offset<40 && !hidden){
      setHidden(true)
    }


  }
  const classes = useStyles()
  const [loaded,setLoaded]=useState(false)
  useEffect(()=>{
    if (data)
    setLoaded(true)
  },[data])
useEffect(()=>{
  if(window.innerWidth<1025)
  window.addEventListener('scroll',handlescroll);
  return ()=>{
    window.removeEventListener('scroll',handlescroll)
  }
})
  return (
    <>

    {!hidden&&loaded&&showNav&&
    <div
      className={classes.fabContainer}
    >
      <a href={`tel:${data.contactphone}`}>
        <PhoneIcon color="secondary" fontSize="large" />
      </a>
      <a href={`mailto:${data.contactemail}`}>
        <MessageIcon color="secondary" fontSize="large" />
      </a>
    </div>
}</>
  )
}
