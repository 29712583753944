import React, { useState,useEffect } from "react"
import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import {
  AppBar,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Hidden,
  Typography,
  Button,
  Grid,
  useScrollTrigger,
  Slide,
} from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import Logo from "../../../images/logotriangle.svg"
import Cta from "./cta"

function RaisedBar({ children, window,disabled }) {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    target: window ? window() : undefined,
    threshold: disabled?-1: 40,
  })
  return (
    <Slide appear={disabled} in={trigger}>
      {children}
    </Slide>
  )
}
export default function Menu({ items, showNav,disableScroll,navCta }) {
  const [loaded,setLoaded]=useState(false)
  useEffect(()=>{
    if (items)
    setLoaded(true)
  },[items,showNav])
  const useStyles = makeStyles(theme => ({
    list: {
      fontSize: "14px",
      margin: 0,
      paddingLeft: "0",
      listStyle: "none",
      paddingTop: "0",
      paddingBottom: "0",
      color: "inherit",
      width: "50vw",
    },
    contactMenu: {
      gridArea: "contact",
      justifySelf: "end",
    },
    fullList: {
      width: "auto",
    },
    listItem: {
      float: "left",
      color: "inherit",
      position: "relative",
      display: "block",
      width: "auto",
      margin: "0",
      padding: "0",
      overflow: "hidden",
      "&:hover": {
        "& span": {
          transform: "translate(0,0)",
        },
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        "&:after": {
          width: "calc(100% - 30px)",
          content: '""',
          display: "block",
          height: "1px",
          marginLeft: "15px",
          backgroundColor: "#e5e5e5",
        },
      },
    },
    listItemText: {
      padding: "0 !important",
    },
    navLink: {
      color: "#343434",
      position: "relative",
      padding: "0.9375rem",
      fontWeight: "400",
      fontSize: "12px",
      textTransform: "uppercase",
      borderRadius: "3px",
      lineHeight: "20px",
      textDecoration: "none",
      margin: "0px",
      display: "inline-flex",
      "&::before,&::after": {
        boxSizing: "inherit",
        content: "",
        position: "absolute",
        width: "100%",
        height: "100%",
      },
      [theme.breakpoints.down("sm")]: {
        width: "calc(100% - 30px)",
        marginLeft: "15px",
        marginBottom: "8px",
        marginTop: "8px",
        textAlign: "left",
        "& > span:first-child": {
          justifyContent: "flex-start",
        },
      },
    },
    navLinkActive: {
      color: "inherit",
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
    underline: {
      border: "2px solid",
      borderColor: theme.palette.primary.main,
      width: "95%",
      display: "none",
      transform: "translate(-100%,0)",
      position: "absolute",
      top: 35,
      "@media (min-width: 992px)": {
        display: "block",
      },
    },
    menuGrid: {
      display: "grid",
      gridTemplateColumns: "5vw 30vw 1fr 1fr",
      gridTemplateAreas: `"logo brand menu contact"`,
      [theme.breakpoints.between('md','lg')]:{
      gridTemplateColumns:"5vw 20vw 1fr 2fr",
      },
      [theme.breakpoints.down('md')]:{
        gridTemplateColumns:"5vw 1fr 10vw",
        gridTemplateAreas: `"logo brand menu"`,
      }
    },
    logo: {
      gridArea: "logo",
      alignSelf: "center",
      justifySelf: "start",
    },
    brand: {
      gridArea: "brand",
      alignSelf: "center",
      justifySelf: "left",
      textAlign: "center",
      zIndex: "9",
      fontSize:"1.5rem",
      textTransform:"uppercase",
      [theme.breakpoints.down("md")]: {

        justifySelf: "center",
        paddingLeft:theme.spacing(6)
      },

    },
    menu: {
      gridArea: "menu",
      alignSelf: "center",
      justifySelf: "center",
      zIndex: "10",
    },
    navButton: {
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    title: {
      [theme.breakpoints.down('xs')]:{
        fontSize:"1.01rem",
        paddingLeft:"10px"
      },
      '@media (max-width:335px)':{
        fontSize:"0.75rem",
        paddingLeft:"10px",
        paddingRight:"0",
        marginRight:0
      }
    },
  }))
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const toggleDrawer = () => {
    setOpen(!open)
  }
  const sideList = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
    >
      <List>
        {items.mainNavigation.map(item => (
          <ListItem button key={item.page?item.page.title:item.slug.current}>
            <Link  style={{textDecoration:"none"}} to={item.slug.current==='/'?'/':`/${item.slug.current}`} >
            <ListItemText primary={item.page?item.page.title:item.slug.current} />
            </Link>
          </ListItem>
        ))}
      </List>
    </div>
  )
  const fullList = () => (
    <>
      <List className={classes.menu}>
        <Grid
          container
          wrap="nowrap"
          alignItems="center"
          justify="space-between"
          spacing={2}
        >
          {items.mainNavigation.map((item, index) => (
            <Grid item key={`${item.page?item.page.title:item.slug}-${index}`}>
              <ListItem className={classes.listItem}>
                <Link to={item.slug.current==='/'?'/':`/${item.slug.current}`} className={classes.navLink}>
                  {item.page?item.page.title:item.slug.current}
                </Link>
                <span className={classes.underline}></span>
              </ListItem>
            </Grid>
          ))}
        </Grid>
      </List>
      <List className={classes.contactMenu}>
        <Grid container spacing={3} justify="center" alignItems="center">
          <Grid item>
            <ListItem className={classes.listItem} key={items.contactphone}>
              <Button
                variant="contained"
                color="primary"
                href={`tel:${items.contactphone}`}
                className={classes.navButton}
              >
                Call {items.contactphone}
              </Button>
            </ListItem>
          </Grid>
          <Grid item>
            <ListItem className={classes.listItem} key={items.contactphone}>
              <Button
                variant="contained"
                color="primary"
                href={`mailto:${items.contactemail}`}
                className={classes.navButton}
              >
                Email {items.contactemail}
              </Button>
            </ListItem>
          </Grid>
        </Grid>
      </List>
    </>
  )

  return (
    <>
    {loaded&&
    <RaisedBar disabled={disableScroll}>
      <AppBar color="secondary">
        <Toolbar className={classes.menuGrid}>
          <div className={classes.logo}>
            <Logo />
          </div>
          <div className={classes.brand}>
            <Typography variant="h5" className={classes.title}>
              {items.title}
            </Typography>
          </div>
          {showNav ? (
            <>
              <Hidden lgUp>
                <MenuIcon
                  className={classes.menu}
                  onClick={toggleDrawer}
                />
              </Hidden>
              <Hidden  mdDown>{fullList()}</Hidden>
            </>
          ):
          <Hidden mdDown>
         {!disableScroll&&navCta&&
          <div className={classes.contactMenu}>
          <Cta data={navCta} fullWidth size="small" contained primary TypographyVariant="body2"/>
          </div>}
          </Hidden>
          }
        </Toolbar>
        {showNav && (
          <Drawer anchor="right" open={open} onClose={toggleDrawer}>
            {sideList()}
          </Drawer>
        )}
      </AppBar>
    </RaisedBar>

}{disableScroll&&<Toolbar/>}
    </>
  )
}
