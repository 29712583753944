import React, { useState } from "react"
import {
  Button,
  Dialog,
  DialogTitle,
  Box,
  Typography,
  DialogActions,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@material-ui/core"
import SanityForm from "./sanityform"
import slugify from "@sindresorhus/slugify"
export default function Cta({
  data,
  size = "large",
  color = "primary",
  variant = "contained",
  elevation = 2,
  fullWidth = false,
  TypographyVariant = "h5",
}) {
  const [show, setShow] = useState(false)
  const theme=useTheme()
  const isPhone=useMediaQuery(theme.breakpoints.down('sm'))
  const DisplayButton = () => {
    if (data.form) {
      const { formTitle, fields, _rawFields, emailTemplate } = data.form
      return (
        <>
          <Button
            elevation={elevation}
            size={size}
            fullWidth={fullWidth}
            variant={variant}
            color={color}
            onClick={() => setShow(true)}
          >
            <Typography variant={TypographyVariant} component="span">
              {data.title}
            </Typography>
          </Button>
          <Dialog open={show} maxWidth="lg" onClose={() => setShow(false)}>
            <DialogActions style={{ backgroundColor: "#d69e5a" }}>
              <IconButton
                style={{ height: "36px" }}
                color="secondary"
                onClick={() => setShow(false)}
              >
                x
              </IconButton>
            </DialogActions>
            <Box p={6}>
              <DialogTitle color="primary" title={formTitle}>
                {formTitle}
              </DialogTitle>
              <SanityForm
                fields={fields ? fields : _rawFields}
                template={emailTemplate}
                closeIt={setShow}
              />
            </Box>
          </Dialog>
        </>
      )
    } else {
      const link = data.link
        ? data.link
        : data.route
        ? slugify(data.route.slug.current)
        : "#"
      return (
        <>
          {" "}
          {data.title ? (
            <Button
              variant={variant}
              size={size}
              fullWidth={fullWidth}
              elevation={elevation}
              color={color}
              href={link}
            >
              {size === "large" ? (
                <Typography variant={isPhone?"subtitle1":"h6"} style={{padding: isPhone?".1rem":"1rem"}}> {data.title} </Typography>
              ) : (
                data.title
              )}
            </Button>
          ) : (
            ""
          )}
        </>
      )
    }
  }

  return (
    <>
      <DisplayButton />
    </>
  )
}
